





































































































import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateUtils } from '@/util/date-utils';
import DropdownNotes from '@/components/dropdowns/dropdown-notes.vue'
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import IconBar from '@/components/containers/icon-bar.vue';
import { LanguageIds } from '@/util/enums/language-ids';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import { Note } from '@/interfaces/note';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementPhase } from '@/interfaces/criterias/procurement-phase';
import { Project } from '@/interfaces/project';
import { ProjectPhase } from '@/interfaces/criterias/project-phase';
import { RouterNames } from '@/util/enums/router-names';
import { Update } from '@/interfaces/update';
import UpdateList from '@/components/general-components/update-list.vue';
import { updateService } from '@/services/restapi/update-service';

@Component({
  components: {
    DropdownNotes,
    IconBar,
    ModalNoteEdit,
    ModalNoteDelete,
    UpdateList,
  },
})
export default class ListItemCard extends Vue {
  @Prop({ required: true }) listItem: Project | Procurement;
  @Prop({ required: false }) list: List;
  selectedNote: Note = null;

  updatesData: Update[] = null;

  get nextDeadline(): string {
    const dates: string[] = [];

    if (this.isProcurement) {
      dates.push((this.listItem as Procurement).offersTo);
      dates.push((this.listItem as Procurement).materialTo);
      dates.push((this.listItem as Procurement).inquiryTo);
      dates.push((this.listItem as Procurement).participationTo);
      return dateUtils.findNextDate('DD-MM-YYYY', dates);
    }

    return null;
  }

  get lastUpdated(): string {
    return formatUtils.formatDate(this.listItem.lastUpdateDate, 'DD.MM.YYYY');
  }

  get published(): string {
    return formatUtils.formatDate(this.listItem.published, 'DD.MM.YYYY');
  }

  get isProcurement(): boolean {
    return globalFunctions.isProcurement(this.listItem);
  }

  get getPhase(): ProcurementPhase | ProjectPhase {
    return globalFunctions.isProcurement(this.listItem) ? this.listItem.procurementPhase : this.listItem.projectPhase;
  }

  get routeName() {
    return this.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW;
  }

  get noteId() {
    return `note-list-${this.commonId}`;
  }

  get commonId() {
    return `${this.isProcurement ? 'proc' : 'proj'}-${this.listItem.id}`;
  }

  getCriteriaNamesOfType(typeId: number): string[] {
    return this.listItem.criteria?.filter(c => c.typeId === typeId).map(c => c.name) ?? [];
  }

  formatPrice(price: number) {
    let formattedPrice = this.$n(price, 'currency', domainUtils.domain.numberFormat);
    if (this.$vmx.language.language.id === LanguageIds.danish) {
      formattedPrice = formattedPrice.replace(' kr.', ',-');
    }
    return formattedPrice;
  }

  openDeleteNoteModal(id: number) {
    this.selectedNote = this.listItem.notes.find((note) => note.id == id);
    this.openModal(`delete-${this.noteId}`);
  }

  noteSaved(note: Note) {
    if (this.listItem.notes.some((x) => x.id === note.id)) {
      this.listItem.notes = this.listItem.notes.map((obj) =>
        obj.id === note.id ? note : obj,
      );
    } else {
      this.listItem.notes.push(note);
      this.listItem.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  noteDeleted(id: number) {
    this.listItem.notes.splice(
      this.listItem.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  get updates(): Update[] {
    if (this.updatesData != null) {
      return this.updatesData
    }

    if (this.isProcurement) {
      updateService.getUpdatesByProcurementId(this.listItem.id).then(updates => this.updatesData = updates)
    } else {
      updateService.getUpdatesByProjectId(this.listItem.id).then(updates => this.updatesData = updates)
    }

    return this.updatesData;
  }
}
