
























































































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Blacklist } from '@/interfaces/blacklist';
import { BvEvent } from 'bootstrap-vue';
import { CriterionType } from '@/interfaces/criterias/criterion-type';
import DropdownIconBar from '@/components/dropdowns/dropdown-icon-bar.vue';
import DropdownNotes from '@/components/dropdowns/dropdown-notes.vue'
import DropdownUpdates from '@/components/dropdowns/dropdown-updates.vue';
import { File } from '@/interfaces/file';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import moment from 'moment';
import { Note } from '@/interfaces/note';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { RouterNames } from '@/util/enums/router-names';
import { SearchItemListViewObject } from '@/interfaces/search/search-item-list-view-object';

@Component({
  components: {
    DropdownIconBar,
    DropdownNotes,
    DropdownUpdates,
    ModalNoteEdit,
    ModalNoteDelete,
  },
})
export default class SearchItemsListView extends Vue {
  @Prop({ required: true }) searchItems: Array<Procurement | Project | ProcurementReference>;
  @Prop({ default: false, type: Boolean }) hideRelatedProcurements: boolean;
  @Prop({ default: false }) showNotes: boolean;
  @Prop({ default: null}) list: List;
  @Prop({ default: null }) filterFunc: (val: Procurement | Project | ProcurementReference) => boolean;
  @Prop({ default: false }) colorSharedBlacklist: boolean;
  @Ref() tableContainer: HTMLDivElement;
  selectedNote: Note = null;
  tableSize = 4000;
  allowClick = false;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    if (this.tableContainer) {
      this.tableSize = this.tableContainer.clientWidth;
    }
  }

  get items(): SearchItemListViewObject[] {
    const items: SearchItemListViewObject[] = [];
    this.searchItems
      .filter(this.filterFunc ?? (() => true) )
      .forEach((searchItem: Procurement | Project | ProcurementReference, searchItemIndex) => {
        if (globalFunctions.isProcurementOrProcurementReference(searchItem)) {
          if (globalFunctions.isProcurementAndNotProcurementReference(searchItem)) {
            items.push(this.createObjectFromProcurement(searchItem));
          } else {
            const isLastChild = searchItemIndex == this.searchItems.length - 1;
            items.push(this.createObjectFromProcurementReference(searchItem, isLastChild));
          }
        } else {
          items.push(this.createObjectFromProject(searchItem));
          if (this.hideRelatedProcurements == false) {
            searchItem.procurements.forEach((procurement, childIndex) => {
              const isLastChild = childIndex == searchItem.procurements.length - 1;
              items.push(
                this.createObjectFromProcurementReference(
                  procurement,
                  isLastChild,
                ),
              );
            });
          }
        }
      });

    return items;
  }


  get userBlacklist() {
    return this.$vmx.blacklist.userBlacklists;
  }

  get isShared(): boolean {
    return this.list && this.list.paUserIds.length > 1;
  }

  getCriteriaStringsOfType(item: SearchItemListViewObject, typeId: number): string[] {
    return item.criteria?.find(criterionData => criterionData.typeId === typeId)?.criteria ?? [];
  }

  public resizeHandler() {
    if (this.tableContainer) {
      this.tableSize = this.tableContainer.clientWidth;
    }
  }

  noteId(searchItem: SearchItemListViewObject) {
    return `note-list-${this.commonId(searchItem)}`;
  }

  commonId(searchItem: SearchItemListViewObject) {
    return `${this.isProcurement(this.getSearchItem(searchItem)) ? 'proc' : 'proj'}-${searchItem.id}`;
  }

  firstListWithObject(searchItem: Procurement | Project | ProcurementReference): List {
    if (this.isOnList(searchItem)) {
      return this.$vmx.list.listsWithObject(searchItem)[0];
    }
    return null;
  }

  isOnList(searchItem: Procurement | Project | ProcurementReference): boolean {
    return this.$vmx.list.isInList(searchItem);
  }

  isProcurement(item: Procurement | Project | ProcurementReference) {
    return globalFunctions.isProcurementOrProcurementReference(item);
  }


  isBlacklistedSharedPred(data: SearchItemListViewObject) {
    if (!this.colorSharedBlacklist) {
      return false;
    }
    const isProcurement = data.isProcurement;
    return this.$vmx.blacklist.blacklists.some((blacklist: Blacklist) => {
      if (isProcurement && data.id == blacklist.procurementId) {
        return blacklist.usersIds.length >= 2;
      } else if (!isProcurement && data.id == blacklist.projectId) {
        return blacklist.usersIds.length >= 2;
      }
      return false;
    })
  }

  showAtSize(minSize: number) {
    return [
      this.tableSize < minSize ? 'd-none' : '',
    ];
  }

  minWidthAtSize(minSize: number) {
    return [
      this.tableSize < minSize ? 'min-width-10-rem' : '',
    ];
  }

  private routerLink(item: SearchItemListViewObject) {
    return {
      name: item.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
      params: { id: item.id.toString() },
    };
  }

  routeToViewPage(item: SearchItemListViewObject) {
    this.$router.push({
      name: item.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
      params: { id: item.id.toString() },
    });
  }

  private blockSlowClicks() {
    this.allowClick = true;
    globalFunctions.timeout(100).then(() => {
      this.allowClick = false;
    });
  }

  private createObjectFromProject(project: Project): SearchItemListViewObject {
    const criteria: {typeId: number, criteria: string[]}[] = [];
    if (project.criteria) {
      for (const criterion of project.criteria) {
        if (criteria.some(c => c.typeId === criterion.id)) {
          criteria.find(c => c.typeId === criterion.id).criteria.push(criterion.name);
        } else {
          criteria.push({
            typeId: criterion.typeId,
            criteria: [criterion.name],
          });
        }
      }
    }
    return {
      id: project.id,
      isProcurement: false,
      isChild: false,
      isLastChild: false,
      coverImage: project.coverImage,
      name: project.name,
      phase: project.projectPhase.name,
      criteria,
      price: project.budget,
      deadline: this.$t('general.emptyField').toString(),
      published: project.published,
      updated: project.lastUpdateDate,
      _showDetails: true,
      notes: project.notes ? project.notes : null,
    };
  }

  private createObjectFromProcurement(
    procurement: Procurement,
  ): SearchItemListViewObject {
    const criteria: {typeId: number, criteria: string[]}[] = [];
    if (procurement.criteria) {
      for (const criterion of procurement.criteria) {
        if (criteria.some(c => c.typeId === criterion.id)) {
          criteria.find(c => c.typeId === criterion.id).criteria.push(criterion.name);
        } else {
          criteria.push({
            typeId: criterion.typeId,
            criteria: [criterion.name],
          });
        }
      }
    }
    return {
      id: procurement.id,
      isProcurement: true,
      isChild: false,
      isLastChild: false,
      name: procurement.name,
      phase: procurement.procurementPhase.name,
      criteria,
      price: procurement.budget,
      deadline: this.nextDeadline(procurement),
      published: procurement.published,
      updated: procurement.lastUpdateDate,
      _showDetails: true,
      notes: procurement.notes ? procurement.notes : null,
    };
  }

  private createObjectFromProcurementReference(
    procurementReference: ProcurementReference,
    isLastChild?: boolean,
  ): SearchItemListViewObject {
    return {
      id: procurementReference.id,
      isProcurement: true,
      isChild: true,
      isLastChild: isLastChild,
      name: procurementReference.name,
      phase: procurementReference.procurementPhase,
      criteria: procurementReference.criteria,
      price: procurementReference.budget,
      deadline: this.nextDeadline(procurementReference),
      published: procurementReference.published,
      updated: procurementReference.lastUpdateDate,
      _showDetails: true,
    };
  }

  private getSearchItem(item: SearchItemListViewObject) {
    for (const searchItem of this.searchItems) {
      if (this.itemsMatch(item, searchItem)) {
        return searchItem;
      } else if (
        item.isChild
        && !globalFunctions.isProcurementOrProcurementReference(searchItem)
        && searchItem.procurements && searchItem.procurements.length > 0
      ) {
        for (const proc of searchItem.procurements) {
          if (this.itemsMatch(item, proc)) {
            return proc;
          }
        }
      }
    }
  }

  private itemsMatch(item: SearchItemListViewObject, searchItem: Project | Procurement | ProcurementReference): boolean {
    return item.id === searchItem.id && item.isProcurement === globalFunctions.isProcurementOrProcurementReference(searchItem);
  }

  private rowClicked(
    item: SearchItemListViewObject,
    index: number,
    event: BvEvent,
  ) {
    if (this.allowClick) {
      this.$router.push({
        name: item.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
        params: { id: item.id.toString() },
      });
    }
  }

  private nextDeadline(searchItem: ProcurementReference | Procurement): string {
    if (
      this.isNextDeadline(
        searchItem.materialTo,
        searchItem.offersTo,
        searchItem.inquiryTo,
        searchItem.participationTo,
      )
    ) {
      return formatUtils.formatDate(searchItem.materialTo, 'DD.MM.YYYY');
    } else if (
      this.isNextDeadline(
        searchItem.offersTo,
        searchItem.materialTo,
        searchItem.inquiryTo,
        searchItem.participationTo,
      )
    ) {
      return formatUtils.formatDate(searchItem.offersTo, 'DD.MM.YYYY');
    } else if (
      this.isNextDeadline(
        searchItem.inquiryTo,
        searchItem.materialTo,
        searchItem.offersTo,
        searchItem.participationTo,
      )
    ) {
      return formatUtils.formatDate(searchItem.inquiryTo, 'DD.MM.YYYY');
    } else if (
      this.isNextDeadline(
        searchItem.participationTo,
        searchItem.materialTo,
        searchItem.offersTo,
        searchItem.inquiryTo,
      )
    ) {
      return formatUtils.formatDate(searchItem.participationTo, 'DD.MM.YYYY');
    }
    return this.$t('general.emptyField').toString();
  }

  private sanitizedText(s: string): string {
    if (s == null) {
      return '&hellip;';
    }
    var span = document.createElement('span');
    span.innerHTML = s;
    if (span.innerText.length < 200) {
      return span.innerText;
    } else {
      return span.innerText.substr(0, 199) + '&hellip;';
    }
  }

  private isNextDeadline(
    date1: string,
    date2: string,
    date3: string,
    date4: string,
  ) {
    const now = moment.now();
    if (date1 == null) {
      return false;
    } else if (moment(date1).isBefore(now)) {
      return false;
    } else if (
      date2 != null &&
      moment(date2).isAfter(now) &&
      moment(date2).isBefore(date1)
    ) {
      return false;
    } else if (
      date3 != null &&
      moment(date3).isAfter(now) &&
      moment(date3).isBefore(date1)
    ) {
      return false;
    } else if (
      date4 != null &&
      moment(date4).isAfter(now) &&
      moment(date4).isBefore(date1)
    ) {
      return false;
    }
    return true;
  }

  private imageFromFile(file: File) {
    if (file) {
      return 'url(' + (file.link ? file.link : this.$vmx.file.fromFile(`${file.code}.${file.extension}`, '1x1')) + ')';
    } else {
      return 'url(' + this.$vmx.file.fromFile(null, '1x1') + ')';
    }
  }

  firstCriteriaText(text: string): string {
    return text ? this.formatText(text, 15) : (this.$t('general.emptyField') as string);
  }

  private formatDate(date: string) {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }

  private formatText(text: string, maxChars: number) {
    if (text.length > maxChars) {
      return text.substring(0, maxChars) + '&hellip;';
    } else {
      return text;
    }
  }

  blacklistRemoved(blacklist: Blacklist) {
    this.$emit('blacklistRemoved', blacklist)
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  openDeleteNoteModal(id: number) {
    for (const searchItem of this.items) {
      const note = searchItem.notes.find((note) => note.id == id);
      if (note) {
        this.selectedNote = note;
        this.openModal(`delete-${this.noteId(searchItem)}`);
      }
    }
  }

  noteSaved(note: Note) {
    const searchItem = this.searchItems.find((searchItem) => {
      const isProcurement = globalFunctions.isProcurementOrProcurementReference(searchItem);
      return searchItem.id === (isProcurement ? this.selectedNote.procurementId : note.projectId);
    });
    if (searchItem.notes.some((x) => x.id === note.id)) {
      searchItem.notes = searchItem.notes.map((obj) =>
        obj.id === note.id ? note : obj,
      );
    } else {
      searchItem.notes.push(note);
      searchItem.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  noteDeleted(id: number) {
    const searchItem = this.searchItems.find((searchItem) => {
      const isProcurement = globalFunctions.isProcurementOrProcurementReference(searchItem);
      return searchItem.id === (isProcurement ? this.selectedNote.procurementId : this.selectedNote.projectId);
    });
    searchItem.notes.splice(
      searchItem.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }
}
